export const enum HttpCodes {
	OK = 200,
	CREATED = 201,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	TO_LARGE = 413,
	TO_MANY_REQUESTS = 429,
}
