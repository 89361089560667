interface ILogoProps {
	primary: string;
	secondary: string;
	width: number;
	height: number;
}

function LayoutLogo(props: ILogoProps): JSX.Element {
	const { primary, secondary, width, height } = props;
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
		>
			<g id='g1'>
				<path
					id='path1'
					fill={primary}
					stroke='none'
					d='M 16.817574 0.599804 C 15.766659 0.701817 15.466397 0.737522 14.965961 0.824234 C 11.427883 1.431202 8.109996 3.170506 5.552771 5.766708 C 1.659383 9.714563 -0.097146 15.1824 0.758599 20.68084 C 1.514256 25.531502 4.39176 29.90781 8.600423 32.606026 C 9.376098 33.10078 10.922442 33.881172 11.743156 34.197407 C 16.49229 35.987717 21.741859 35.651077 26.175716 33.2742 C 29.813883 31.325775 32.691387 28.091999 34.182686 24.281857 C 34.963364 22.287525 35.313667 20.466614 35.368717 18.145844 L 35.398743 16.937004 L 32.035816 16.937004 L 28.677895 16.937004 L 28.677895 17.329748 C 28.677895 18.145844 28.572803 19.375088 28.447693 19.997358 C 27.556919 24.511383 23.833679 27.944082 19.219664 28.515348 C 18.113701 28.653065 16.392202 28.540852 15.336284 28.265419 C 11.963348 27.388119 9.235975 24.980637 7.989892 21.777466 C 6.518611 17.992826 7.349334 13.693026 10.126751 10.734684 C 11.818222 8.929073 14.000121 7.781441 16.427233 7.424398 C 16.712482 7.378494 17.388069 7.332588 17.933544 7.312185 L 18.919401 7.281582 L 18.919401 3.920292 L 18.919401 0.564102 L 17.958565 0.574303 C 17.428104 0.574303 16.912655 0.589603 16.817574 0.599804 Z'
				/>
				<path
					id='path2'
					fill={secondary}
					stroke='none'
					d='M 23.093035 10.69388 C 20.400692 14.504021 18.148731 17.68679 18.098688 17.7684 C 18.00861 17.906116 18.028627 17.901016 18.599123 17.610283 C 20.200518 16.794188 33.5121 9.831879 33.552135 9.796175 C 33.587166 9.760469 28.142427 3.838684 28.027328 3.787678 C 28.007311 3.782578 25.79038 6.888838 23.093035 10.69388 Z'
				/>
			</g>
		</svg>
	);
}

export default LayoutLogo;
